.dontHaveAcc a,
.forgotPassword {
    color: #f33a58;
}

.dontHaveAcc {
    font-size: 1.4rem;
    line-height: 1.8;
    margin-bottom: 0;
    padding: 24px 16px 0;
    margin-top: 14px;
}

.forgotPassword {
    font-size: 1.4rem;
    line-height: 1.8;
    margin-top: 5px;
    cursor: pointer;
}

.submitBtn {
    padding: 12px 20px;
    margin-top: 20px;
    width: 100%;
    font-weight: 600;
}

.back-btn {
    position: absolute;
    top: 0;
    left: 0;
    min-width: 0;

    &:hover {
        background-color: #dce0e3;
    }
}

.login-btn {
    width: 80%;
    border-color: #dce0e3;
    height: 40px;
    margin-top: 10px;

    span:first-child {
        position: absolute;
        top: 50%;
        left: 16px;
        translate: 0 -50%;
    }

    &:hover {
        background-color: #dce0e3;
    }
}
