.wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: 16px;
    align-items: center;
}

.control {
    flex: 1;
    font-size: 1.4rem;
    background-color: #fff;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    margin-bottom: 8px;
    padding: 8px;
}
