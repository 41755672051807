.submitBtn {
    padding: 12px 20px;
    margin-top: 20px;
    width: 100%;
    font-weight: 600;
}

.haveAcc {
    font-size: 1.4rem;
    line-height: 1.8;
    margin-bottom: 0;
    padding: 24px 16px 0;
    margin-top: 14px;
}

.haveAcc a {
    color: #f33a58;
}
