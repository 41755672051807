@import 'normalize.css';
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap');

:root {
    --primary-color: #81ecec;
    --black-color: #000;
    --text-color: #161823;
    --white-color: #fff;
    --invalid-color: #f33a58;
    --button-color: #1dbfaf;
    --divider-color: #dee3e9;
}

* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}

html {
    font-size: 62.5%;
}

body {
    font-family: 'Roboto', sans-serif;
    font-size: 1.6rem;
    line-height: 1.5;
    text-rendering: optimizeSpeed;
    overflow-y: overlay;
}

a {
    text-decoration: none;
}

ul {
    list-style: none;
}

button,
input {
    border: 0;
    outline: 0;
}

input {
    &:focus {
        box-shadow: 0 0 0 0.25rem #1dbfaf4c;
    }
}
