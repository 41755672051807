.wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-top: 8px;
    width: 100%;
}

.label {
    font-size: 1.6rem;
    margin-bottom: 16px;
    font-weight: 500;
}

.desc {
    color: #757575;
    font-size: 1.4rem;
    line-height: 2rem;
    margin: 14px 0;
}
