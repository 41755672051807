.wrapper {
    margin-bottom: 30px;
}

.heading {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    color: var(--text-color);
    font-size: 2.2rem;
    margin: 0 0 20px;
    padding: 10px 0;
}
