.wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    overflow: hidden;
    position: relative;
    user-select: none;
}

.container {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.content {
    --width: 100%;
    border-radius: 8px;
    max-width: calc(100vw - 32px);
    min-height: 550px;
    // padding: 32px 16px 60px;
    // padding: 8px 16px 8px;
    position: relative;
    width: var(--width);
}

.header {
    margin-top: 30px;
}

.logo {
    font-size: 3.2rem;
}

.title {
    color: #292929;
    font-size: 2.8rem;
    font-weight: 700;
    line-height: 1.4;
    margin: 12px 0;
}

.desc {
    font-size: 1.3rem;
    line-height: 1.5;
    margin: 0 auto;
    width: min(400px, 90%);
}

.desc,
.dontHaveAcc a,
.forgotPassword {
    color: #f33a58;
}

.body {
    display: flex;
    align-items: center;
    flex-direction: column;
}

.formBody {
    margin-top: 8px;
    max-width: 100%;
    width: 380px;
}

.dontHaveAcc {
    font-size: 1.4rem;
    line-height: 1.8;
    margin-bottom: 0;
    padding: 24px 16px 0;
    margin-top: 14px;
}

.forgotPassword {
    font-size: 1.4rem;
    line-height: 1.8;
    margin-top: 5px;
    cursor: pointer;
}

.acceptTerm {
    font-size: 1.1rem;
    width: 400px;
    padding: 16px;
    width: min(400px, 100%);
    color: #666;
}

.login-btn {
    width: 100%;
    height: 40px;
    margin-top: 10px;

    span:first-child {
        position: absolute;
        top: 50%;
        left: 16px;
        translate: 0 -50%;
    }
}
