.wrapper {
    position: fixed;
    inset: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow-y: auto;
    overflow-x: hidden;
    z-index: 999;
    padding: 20px 0;

    background: rgba(0, 0, 0, 0.4);

    animation-duration: 0.5s;
    animation-name: inline;
    animation-timing-function: linear;

    &.closing .content {
        animation-name: inlineOut;
    }
}

.content {
    animation-duration: 0.5s;
    animation-name: inlineIn;
    background: #fff;
    border-radius: 16px;
    margin: auto;
    position: relative;
    width: min(540px, 100% - 32px);
    z-index: 1;
}

.close {
    align-items: center;
    background: rgba(22, 24, 35, 0.03);
    border-radius: 50%;
    color: #333;
    cursor: pointer;
    display: flex;
    font-size: 2.4rem;
    height: 40px;
    justify-content: center;
    line-height: 0;
    position: absolute;
    right: 16px;
    top: 16px;
    width: 40px;
    z-index: 1;
}

.body {
    border: none;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 16px;
    gap: 8px;
}

.heading {
    color: var(--text-color);
    font-size: 2rem;
    font-weight: 600;
    margin: 20px 0;
}

@keyframes inline {
    0% {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes inlineIn {
    0% {
        opacity: 0;
        scale: 0;
    }

    to {
        opacity: 1;
        scale: 1;
    }
}

@keyframes inlineOut {
    0% {
        opacity: 1;
        scale: 1;
    }
    to {
        opacity: 0;
        scale: 0;
    }
}
